import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import Image from "react-bootstrap/Image";
import "./PartFinder.css";
// import { InputGroup } from "react-bootstrap";

function PartFinder() {
  return (
    <Container>
      <Container></Container>
    </Container>
  );
}

export default PartFinder;
