import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import "./Navigation.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Image } from "react-bootstrap";
import HomeLogo from "../../assets/logos/navLogo.png";
import Button from "react-bootstrap/Button";

function Navigation() {
  //context
  const [auth, setAuth] = useAuth();
  //hook
  const navigate = useNavigate();
  // const categories = useCategory();

  //console.log("categories => ", categories);

  const logout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.removeItem("auth");
    navigate("/login");
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        {/* <Navbar.Brand className="logoHome" href="/">Torque Tech Truck & Trailer Repair</Navbar.Brand> */}
        <Navbar.Brand href="/">
          <Image className="logoHome" src={HomeLogo}></Image>
          <span>TORQUE TECH TRUCK & TRAILER REPAIR LTD.</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/inventory">Part Finder</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/contact">Contact Us</Nav.Link>

            {auth?.user ? (
              <NavDropdown
                title={auth?.user?.name}
                id="navbarScrollingDropdown"
                style={{ lineHeight: "25px" }}
              >
                <NavDropdown.Item
                  href={`/dashboard/${
                    auth?.user.role === 1 ? "admin" : "user"
                  }`}
                >
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Button onClick={logout}>Logout</Button>
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
